.circuit {
    padding: 0% 9% 0% 9%;
    width: 100%;
}

.circuit-container {
    border: solid 1px blue;
}

.circuit-short-description p {
    /* color: #009EE3; */
    color: #0064A1;
    text-align: justify;
}

.circuit-top-title h1 {
    text-align: right;
}

.circuit-top-title hr {
    background-color: #336699;
    height: 1px;
}
