ul {
    display: block;
}

table {
    border: solid 2px black;
    width: 100%;
}

th, td {
    border: solid 1px black;
    text-align: center;
}

td {
    font-weight: bold;
}

th {
    background-color: lightgray;
}

tr {
    text-align: center;
}

.importantNotes {
    border: 0%;
    color: red;
    font-size: 15px;
    margin: 0%;
    padding: 0%;
}

.preview-tour {
    display: block;
    padding: 3%;
}

.preview-tour-container {
    display: grid;
    border: solid 1px #336699;
    grid-template-columns: 30% 70%;
    padding: 2.5%;
    width: 100%;
}

.preview-tour-content {
    display: grid;
    text-align: justify;
}

.preview-tour-content ul {
    display: block;
}

.preview-tour-img {
    /* border: solid 1px red; */
    display: grid;
    grid-template-rows: 33% 33% 33%;
    width: 22vw;
}

.preview-tour-img img {
    width: 100%;
}

.preview-tour-price {
    color: red;
}

.preview-tour-title {
    font-size: 5vh;
}

.schedules {
    display: grid;
    grid-template-columns: 25% 25% 25%;
}

.tabla-precios {
    width: 100%;
}

@media (max-width: 1170px) {
    .importantNotes {
        font-size: 10px;
    }

    .preview-tour{
        display: block;
    }

    .preview-tour-container {
        display: block;
    }

    .preview-tour-container img {
        display: block;
        margin: none;
        width: 100%;
    }

    .preview-tour-content {
        display: block;
        padding: 0% 5% 0% 5%;
    }

    .preview-tour-img {
        width: 100%;
    }

    .preview-tour-main-title {
        font-size: 24px;
    }

    .preview-tour-title {
        font-size: 2.25vh;
        text-align: center;
    }

    .preview-tour-subtitle {
        font-size: 16px;
    }

    .preview-tour-price {
        border: solid 3px red;
        border-radius: 45px;
        padding: 4% 4% 2% 4%;
    }

    .schedules {
        display: block;
    }

    table {
        width: 100%;
    }
}