.grid-order-resume {
    display: grid;
}

.grid-order-resume-text {
    display: flex;
    justify-content: space-between;
    padding: 0 10% 0 10%;
}

.tour-included {
    margin-top: 0;
}