body {
  background-color: #E3E6E6;
  color: #0F1111;
  scroll-behavior: smooth;
  scroll-padding-top: 25%;
  padding-top: 5%;
}

#btn-reservation-button {
  display: none;
}

#navbar-menu {
  background-color: #0070B8;
  color: white;
  font-weight: bold;
  opacity: 1;
  position: relative;
}

#background-img {
  width: 100%;
  height: max-content;
}

#showHideTikalForm {
  text-decoration: underline;
}

#showHideTikalForm:hover {
  border: #0F1111;
  color: gray;
  cursor: pointer;
  font-size: large;
  transition: 300ms;
}

.showReservation {
  text-decoration: underline;
}

#welcome {
  background-image: url('../public/assets/La-Atitlan.jpg');
  /* position: fixed; */
}

.about-section {
  padding: 0% 5% 0% 1%;
}

.answer {
  background-color: lightgray;
  border-radius: 5px;
  padding: 1rem 0px 1rem 2.5%;
}

.bold-price {
  /* border: solid 1px green; */
  display: flex;
  font-size: larger;
  margin-bottom: none;
  margin-top: 3%;
  padding: 3% 0% 0% 0%;
  text-align: center;
  justify-content: center;
}

.bold-price b {
  margin-right: 5px;
}

.bold-price p {
  margin-left: 5px;
}

.btn-warning {
  margin-bottom: 3%;
}

.calification {
  display: flex;
}

.calification-star, .included-dots {
  height: 20px;
  margin-left: 3px;
  margin-right: 0.2rem;
  width: 20px;
}

.card {
  width: 100%;
  padding: 2rem;
  box-shadow: 1px 1px gray, -1px -1px gray, -1px 1px gray, 1px -1px gray;
  border: solid gray 1px;
}

.card:hover {
  box-shadow: 5px 5px lightgray, -5px -5px lightgray, -5px 5px lightgray, 5px -5px lightgray;
  cursor: pointer;
}

.card:hover > img {
  transform: scale(1.05);
}

.card-img-text {
  position: fixed;
  color: white;
  font-size: 20pt;
  display: none;
}

.card-img-top {
  border-radius: 10px;
  transition: 0.5s;
  object-fit: cover;
  overflow: hidden;
}

.card-img-top:hover {
  transform: scale(1.03);
}

.card-text {
  text-align: justify;
}

.carousel {
  min-height: 500px;
}

.carousel .carousel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 300px;
  height: 420px;
  /* border: red solid 1px; */
}

.carousel .carousel-item img {
  width: 90%;
  height: 90%;
  object-fit: cover;
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: -1;
  border-radius: 15px;
  border: #1062B3 solid 1px;
}

.carousel-title { 
  color: #fff;
  margin: 7px 0;
  font-size: 35px;
  font-weight: 600;
  text-shadow: 2px 2px 1px rgba(0,0,0,0.7);
  font-family: 'Dancing Script', cursive;
  margin-top: 0;
  top: 25%;
  left: 25%;
}

.connection-text {
  /* border: 1px solid #063970; */
  /* border: 1px solid #336699; */
  background-color: #336699;
  color: #FFF;
  display: block;
  font-family: Helvetica, "Helvetica Neue", Arial, sans-serif;
  padding-bottom: 1px;
  padding-top: 6px;
  position: relative;
  text-align: center;
  width: 80vw;
}

.center-align {
  border: solid 1px green;
  padding: 5%;
}

.circuits {
  padding: 5%;
  text-align: center;
}

.connection-text {
  border-radius: 15px;
}

.connection-text h5 {
  font-size: 3vh;
}

.contact-whatsapp {
  cursor: pointer;
  bottom: 1%;
  margin-left: 90%;
  position: fixed;
  right: 1%;
  width: 4%;
  padding: none;
}

.contact-whatsapp img {
  width: 100%;
}

.container {
  text-align: center;
  padding: 5% 30% 5% 30%;
}

.container-about {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 79vh;
  margin: 1% 2.5% 0% 2.5%;
  text-align: justify;
}

.container-about h1 {
  text-align: center;
}

.container-from {
  display: flex;
  justify-content: center;
  margin: 2vh 0vw 2vh;
}

.dropdown {
  position: relative;
}

.footer-brand {
  display: block;
  text-align: center;
}

.footer-media {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.footer-media a {
  margin: 0% 2% 0% 2%;
}

.footer-row {
  display: block;
  padding: 0% 20% 0% 20%;
}

.form-reservation .form-control {
  margin: 0% 0% 2% 0%;
}

.gallery-img {
  transition: 300ms;
}

.hide {
  display: none;
}

.icono-precio {
  width: 113px;
}

.img-gallery {
  display: grid;
  grid-template-columns: 33% 34% 33%;
  grid-template-columns: 33% 34% 33%;
  place-items: center;
}

.img-gallery img {
  height: 100%;
  margin: solid 1px red;
  width: 100%;
}

.img-non-selected {
  filter: brightness(60%);
}

.is-tikal {
  display: none;
}

.landingPage {
  padding: 0% 10% 0% 10%;
}

.logo {
  position: fixed;
  width: 25rem;
  align-self: center;
}

.map-site {
  border: none;
  height: 100%;
  width: 100%;
  /* height: 450;
  width: 600; */
}

.media-link {
  width: 25%;
}

.min-pax {
  color: red;
  font-size: 18px;
  margin-bottom: 5px;
  text-align: center;
}

.min-pax-non-important{
  color: black;
  font-size: 10px;
  margin-bottom: 5px;
  text-align: center;
}

.nav-link{
  color: black;
}

/* -----INICIO MENU----- */

.navbar-custom {
  /* background-color: #FF8D01; */
  align-items: center;
  background-color: #336699;
  display: flex;
  height: 8%;
  justify-content: space-between;
  padding: 1%;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;
}

.nav-logo {
  padding-left: 5%;
}

.nav-logo img {
  height: auto;
  width: 18vw; /* Tamño de la imagen del logo */
}

.nav-items a {
  margin: 15px;
  color: white;
  position: relative;
  text-decoration: none;
}

.nav-toggle {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    height: 10vh;
  }

  .nav-items {
      position: absolute;
      top: 60px;
      left: 0px;
      background: rgb(85, 170, 255);
      display: flex;
      flex-direction: column;
      width: 100%;
      /* height: -webkit-fill-available; */
      height: 100vh;
      transform: translateX(-100%);
      transition: 0.3s ease all;
  }

  .nav-items.open {
      transform: translateX(0) !important;
  }

  .nav-logo img {
    border: solid 1px white;
    border-radius: 15px;
    height: auto;
    width: 60vw;
  }
  
  .nav-toggle {
      display: flex !important;
      flex-direction: column;
      margin: 15px;
  }

  .nav-toggle span {
      width: 30px;
      height: 4px;
      background: white;
      margin-bottom: 5px;
      border-radius: 2px;
      transform-origin: 5px 0px;
      transition: all 0.2s linear;
  }

  .nav-toggle.open > span {
      transform: rotate(45deg) translate(0px, 0px);
  }

  .nav-toggle.open > span:nth-child(2) {
      display: none;
  }

  .nav-toggle.open > span:nth-child(3) {
      transform: rotate(-45deg) translate(-5px, 1);
  }
}

/* -----FIN----- */

.passengers-amount {
  display: grid;
  grid-template-columns: 80% 20%;
  margin-bottom: 5%;
  margin-top: 5%;
}

.phone-version {
  display: none;
}

.price-col {
  align-items: center;
  display: flex;
  float: right;
  justify-content: center;
  position: relative;
  text-align: center;
  top: -2em;
  right: -3em;
  transform: rotate(-25deg);
}

.promo {
  align-self: right;
  background-color: red;
  border-radius: 100%;
  color: yellow;
  float: right; 
  padding: 5% 5% 5% 5%;
  position: relative;
  transform: rotate(-25deg);
  width: 60%; /* CONTROLA LA CIRCUNFERENCIA DEL PRECIO */
}

.promo p {
  text-align: center;
}

.promo-container {
  align-items: right;
  padding: -5% 1% 20% 0%;
  position: relative;
  text-align: right;
}

.review {
  border: 5rem 0pc 5rem 0pc;
}

.row-promo {
  margin-top: -2.5pc;
  padding-bottom: 2pc;
  text-align: right;
}

.schedules {
  display: block;
}

.schedules-list {
  display: grid;
  grid-template-columns: 50% 50%;
}

.shuttles-img-main {
  width: 100%;
}

.standard-version {
  box-sizing: border-box;
  display: flex;
}

.socialLink {
  color: gray;
}

.socialLink:hover {
  color: darkgray;
  text-decoration: none;
  text-shadow: 3rem;
  cursor: pointer;
}

.standard-version {
  display: block;
}

.tikal-form {
  text-align: justify;
  transition: 300ms;
  width: 100%;
}

.tikal-form form {
  padding: 0% 2% 0% 2%;
}

.tikal-form h4 {
  text-align: center;
}

.tikal-form-customize-price {
  animation-duration: 1.5s;
  animation-name: pricein;
}

/*
ANIMATION 
@keyframes pricein {
  from {
    margin-left: 15%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
} */

.tour {
  padding: 2.5% 5% 2.5% 5%;
}

.tour-container {
  padding: 2% 5% 5% 5%;
}

.tour-container-overview {
  border: solid 2.25px gray;
  height: 20rem;
  margin: 0% 0% 0% 0%;
  width: 22rem;
}

.tour-container-overview-link {
  color: black;
}

.tour-container-overview-link:hover {
  color: gray;
  text-decoration: none;
}

.tour-image {
  display: flex;
  justify-content: left;
  align-items: flex-end;
}

.tour-img-gallery {
  display: grid;
  grid-template-rows: 25% 25% 25% 25%;
}

.tour-img-gallery img {
  margin: .25rem;
  border-radius: 5px;
  width: 100%;
}

.tour-img-main {
  align-items: center;
  display: flex;
}

.tour-img-main img {
  height: 100%;
}

.tour-included {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 2% 5% 2% 5%;
  width: 100%;
}

.tour-main-image {
  animation-duration: 200ms;
  animation-name: slidein;
  border-radius: 5px;
  box-shadow: 1px 1px gray;
  width: 100%;
  /* transition: 200ms; */
}

.tour-overview-info h1 {
  color: black;
  font-size: 30px;
}

.tour-price-container {
  background-color: lightgray;
  color: black;
  height: 100%;
  padding: 1rem;
  border-radius: 5px;
}

.tour-prev-img {
  width: 100%;
}

.tour-title {
  display: block;
}

.tour-top {
  /* border: solid 1px red; */
  display: flex;
}

.tours-departing {
  padding: 0% 2.5% 0% 2.5%;
}

.tour-features {
  display: flex;
}

.tours-from {
  width: 100%;
}

.tours-from-img {
  width: 22rem;
}

.tours-from-img:hover, .tour-container-overview:hover {
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

.tours-from a:hover {
  text-decoration: none;
}

.tours-from div {
  position: relative;
  text-align: center;
}

.tours-from p {
  text-align: center;
  color: white;
  font-size: 2pc;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.tour-included {
  margin-bottom: 1%;
}

.tours-overviews { /* GRID */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  padding: 0% 0% 0% 0%;
}

p {
  text-align: justify;
}

/* PHONE VERSION */
@media (max-width: 1170px) {
  #btn-reservation-button {
    display: block;
  }
  body {
    background-color: white;
    color: #0F1111;
    padding-top: 15.25%;
    scroll-behavior: smooth;
  }
  footer {
    padding: 0% 5% 2% 5%;
  }
  footer hr {
    background-color: black;
    border: solid 0.25px gray;
  }
  hr {
    background-color: lightblue;
    border: solid 2px lightblue;
  }
  nav {
    background-color: #1062B3;
    /* border: solid 1px red; */
    display: flex;
    margin-bottom: 1rem;
    padding: 0% 5% 0% 5%;
    position: relative;
  }
  .additional-access {
    padding: 5% 5% 0% 5%;
    text-align: center;
  }
  .additional-access button {
    margin-right: 3%;
  }
  .btn-gallery {
    background-color: transparent;
    border: none;
    padding: none;
    text-align: justify;
    vertical-align: top;
  }
  .btn-gallery img {
    margin: none;
    width: 100%;
  }
  .card > .row {
    display: block;
    padding: 0px 5% 0px 0.5%;
  }
  .contact-whatsapp {
    bottom: 8%;
    position: fixed;
    right: 3%; /* Controls right separation */
    width: 15%;
  }
  .container {
    padding: 5% 10% 5% 10%;
  }
  .container-about {
    display: grid;
    grid-template-columns: 100%;
    height: auto;
  }
  .footer-brand p {
    text-align: center;
  }
  .footer-row {
    display: block;
    padding: 2.5%;
  }
  .footer-row p {
    color: gray;
    text-align: center;
  }
  .included {
    margin-bottom: 2rem;
  }
  .included-dots {
    height: 19px;
    margin-top: 5px;
    width: 16px;
  }
  .landingPage {
    display: flex;
    color: white;
  }
  .landingPage h3 {
    position: relative;
  }
  .logo {
    /* border: solid 2.5px white;
    border-radius: 50%; */
    position: relative;
    width: 20%;
  }
  .logo-title {
    padding-top: 4%;
  }
  .map-site {
    border: none;
    height: 100%;
    width: 100%;
  }  
  .navbar-brand {
    color: #fff;
    display: flex;
    text-decoration: none;
  }
  .navbar-brand h4 {
    margin-left: 0.5rem;
    margin-top: 3%;
  }
  .navbar-brand h4:hover {
    color: #999;
    text-decoration: none;
  }
  .navbar-brand h4:visited {
    color: #999;
    text-decoration: none;
  }
  .navbar-dropdown {
    background-color: #336699;
    border: solid 1px #FFF;
    text-align: right;
  }
  .navbar-dropdown a {
    background-color: #336699;
    color: #FFF;
  }
  .navbar-dropdown div {
    background-color: #336699;
    color: #336699;
  }
  .nav-link a {
    color: black;
    font-size: 0.875em;
    font-weight: bolder;
  }
  .overview {
    padding: 5%;
  }
  .phone-version {
    display: block;
    border-left: 0px;
    border-right: 0px;
    box-sizing: border-box;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0pc;
    overflow: hidden;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
  .price-col {
    align-items: center;
    /* border: solid 1px green; */
    display: flex;
    float: right;
    justify-content: center;
    margin: none;
    padding: none;
    position: relative;
    right: -60px;
    text-align: center;
    top: -3.5rem;
    transform: rotate(-25deg);
    width: 90px;
  }
  .promo {
    align-self: right;
    background-color: red;
    border-radius: 100%;
    color: yellow;
    float: right; 
    padding: 5% 5% 5% 5%;
    position: relative;
    transform: rotate(-25deg);
    width: 65%; /* CONTROLA LA CIRCUNFERENCIA DEL PRECIO */
  }
  .promo p {
    text-align: center;
  }
  .promo-container {
    align-items: right;
    padding: -5% 1% 20% 0%;
    position: relative;
    text-align: right;
  }
  .reservation-button {
    bottom: 1%;
    cursor: pointer;
    display: block;
    margin-left: 90%;
    padding: none;
    position: fixed;
    right: 3%;
    width: 30%;
  }
  .row-price {
    display: flex;
  }
  .row-price-tour {
    text-align: right;
  }
  .row-promo {
    margin-top: -2.5pc;
    padding-bottom: 2pc;
    text-align: right;
  }
  .schedules-list {
    display: grid;
    grid-template-columns: 100%;
  }
  .standard-version {
    display: none;
  }
  .tour {
    padding: 1% 5% 0% 5%;
  }
  .text-included {
    display: block;
    font-size: 22px;
    margin-left: 0.5rem;
  }
  .toggle-navbar {
    background-color: #336699;
    padding: 5%;
    text-align: right;
  }
  .tour-img-gallery {
    display: flex;
    padding-top: 0.5rem;
  }
  .tour-img-gallery img {
    border-radius: 5px;
    margin: 0.1rem;
    width: 24%;
  }
  .tour-item-included {
    display: flex;
    margin-top: 4%;
  }
  .tour-title {
    background-color: white;
    padding: 0% 5% 0% 5%;
  }
  .tour-container-overview {
    width: 100%;
    padding: 1px;
    box-shadow: 1px 1px gray, -1px -1px gray, -1px 1px gray, 1px -1px gray;
    border: solid gray 1px;
    margin-bottom: 2pc;
  }
  .tour-container-overview-link {
    text-decoration: none;
    color: black;
  }
  .tour-container-overview-link:hover {
    text-decoration: none;
    color: black;
  }
  .tour-container-overview:hover {
    box-shadow: 5px 5px lightgray, -5px -5px lightgray, -5px 5px lightgray, 5px -5px lightgray;
    cursor: pointer;
  }
  .tour-container-overview img:hover {
    transform: scale(1.03);
  }
  .tour-features {
    display: block;
    padding: 0% 2% 0% 2%;
  }
  .tour-img-main {
    width: 100%;
  }
  .tour-included {
    display: block;
    padding: 0% 5% 0% 5%;
  }
  .tour-price-container {
    background-color: lightblue;
    border-radius: 5px;
    padding: 0% 2% 0% 2%;
    text-align: right;
  }
  .tours-from {
    display: block;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 5%;
    padding-right: 5%;
    /* border: solid 1px red; */
  }
  .tours-from img {
    margin-top: 2%;
  }
  .tour-from-link {
    color: white;
  }
  .tour-from-link:hover {
    color: lightgray;
    text-decoration: none;
    cursor: pointer;
  }
  .tours-departing {
    font-size: 15px;
    padding: 0% 5% 0% 5%;
  }
  .tours-from .col-12 {
    border: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .tours-from img {
    width: 100%;
  }
  .tour-overview-info {
    /* border: solid 1px #063970; */
    padding: 0% 5% 0% 2%;
  }
  .tour-overview-info .row {
    margin-top: 1rem;
    padding-bottom: 3%;
  }
  .tour-prev-img {
    border-radius: 2px;
    transition: 0.5s;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
  }
  .tours-overviews {
    display: block;
  }
  #navbar-menu {
    /* border: solid 1px blue; */
    color: white;
    display: block;
    position: absolute;
    height: 45px;
    width: 100%;
  }
}

@media (min-width: 1440px) {
  #btn-reservation-button {
    display: none;
  }
}

@media (max-width: 400px) {
  .fixed-top {
    height: 20vh;
  }
}


